// Booststrap functions and variables
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

// Template variables
@import "./variables";

// Bootstrap main SCSS
@import "node_modules/bootstrap/scss/bootstrap.scss";

// Template mixins
@import "./mixins/animation";
@import "./mixins/badges";
@import "./mixins/blockquote";
@import "./mixins/breadcrumbs";
@import "./mixins/buttons";
@import "./mixins/cards";
@import "./mixins/misc";
@import "./mixins/no_ui_slider";
@import "./mixins/pagination";
@import "./mixins/popovers";
@import "./mixins/tooltips";
@import "./mixins/width";

// Core styles
@import "./background";
@import "./reset";
@import "./functions";
@import "./footer";
@import "./misc";
@import "./utilities";
@import "./demo";
@import "./typography";
@import "./spinner";

@import "./vertical-wrapper";
@import "./navbar";
@import "./sidebar";
@import "./layouts";

// components
@import "./components/accordions";
@import "./components/badges";
@import "./components/bootstrap-alert";
@import "./components/breadcrumbs";
@import "./components/buttons";
@import "./components/cards";
@import "./components/carousel";
@import "./components/checkbox-radio";
@import "./components/dashboard";
@import "./components/datepicker";
@import "./components/dropdown";
@import "./components/forms";
@import "./components/icons";
@import "./components/input-group";
@import "./components/list-group";
@import "./components/modal";
@import "./components/nav";
@import "./components/pagination";
@import "./components/popover";
@import "./components/profile";
@import "./components/rating";
@import "./components/tables";
@import "./components/timeline";
@import "./components/chat";
@import "./components/faq";
@import "./components/auth";

// Email
@import "./components/email/inbox";
@import "./components/email/email-read";
@import "./components/email/email-compose";

// Plugin styles
@import "node_modules/highlight.js/styles/googlecode.css";
@import "../fonts/feather/style.css";
@import "node_modules/flag-icon-css/css/flag-icon.min.css";
@import "node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "node_modules/animate.css/animate.min.css";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/dropzone/dist/min/dropzone.min.css";
@import "node_modules/angular-archwizard/archwizard.css";
@import "node_modules/simple-datatables/dist/style.css";
@import "node_modules/@swimlane/ngx-datatable/index.css";
@import "node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";

// Plugin overrides
@import "./components/plugin-overrides/ng-select";
@import "./components/plugin-overrides/ngx-chips";
@import "./components/plugin-overrides/sweet-alert";
@import "./components/plugin-overrides/dropzone";
@import "./components/plugin-overrides/angular-archwizard";
@import "./components/plugin-overrides/apex-charts";
@import "./components/plugin-overrides/data-tables";
@import "./components/plugin-overrides/ngx-datatable";
