//########### Created by Dipak ##################
//###########Description :- Global color variablr ###############
:root {
  --unique: #880e4f;
  --danger: #ff3547;
  --success: #00c851;
  --info: #33b5e5;
  --white: #ffffff;
  --warning: #ffbb33;
  --heading: #ffffff;
  --para: #989898;
  --para-tint: #4a4a4a;
  --white: #fff;
  --black: #212529;
  --helper: #1471e2;
  --helper-tint: rgb(243, 244, 255);
  --bg: #1471e2;
  --bg-helper: #8ebbf335;
  --bgOne: rgba(20, 113, 226, 0.1);
  --shadow: 0px 0px 20px 0px #8490ff33;
  --shadowOne: 0px 2px 25px 0px #10133151;
  --shadow-support: 0px 7px 5px 0px #1a1e3c33;
  --shadow-tint: 0px 5px 7px -2px #10133151;
  --gradient: linear-gradient(
    -45deg,
    rgba(20, 113, 226, 1) 0%,
    rgba(0, 68, 186, 1) 100%
  );
  --transition: all 300ms linear;
}
.ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  text-align: center;
}
datatable-body-cell.datatable-body-cell.sort-active {
  display: flex;
  justify-content: center;
  align-items: center;
}
// .changecolor datatable-body-cell.datatable-body-cell.sort-active{
//     background-color: #727CF5;
//     color: #000;
// }

.changecolor
  .ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row.active,
.ngx-datatable.bootstrap.changecolor
  .datatable-body
  .datatable-body-row.datatable-row-even.active,
.ngx-datatable.bootstrap.changecolor
  .datatable-body
  .datatable-body-row.active {
  background-color: #727cf5 !important;
}
.changecolor
  .ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row.datatable-row-even {
  background: #fff;
}

@media only screen and (max-width: 450px) {
  .datatable-footer-inner {
    height: auto !important;
    display: flex;
    flex-direction: column;
  }
}
//########## Creation End #################

.outline-button {
  background: var(--white) !important;
  border-radius: 13px !important;
  margin-bottom: 4px;
  margin-right: 4px;
}

:focus {
  outline: none !important;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background: #727cf5;
  border-radius: 10px;
}

/* You can add global styles to this file, and also import other style files */
.sidebar-toggler.not-active .cust-toggle-image {
  display: none !important;
  transition: all 0.1s ease;
}
.sidebar .sidebar-header .sidebar-toggler.active {
  width: 100%;
  .cust-toggle-bar {
    display: none;
    transition: all 0.1s ease;
  }
  .mobile-device-block {
    display: none;
    padding-left: 20px;
    transition: all 0.1s ease;
  }
}
.cursor_pointer {
  cursor: pointer;
}
.border_none {
  border: none !important;
}
.box_shadow_none {
  box-shadow: none !important;
}
.custom-rightSide-modal .modal-dialog,
.custom-order-rightSide-modal .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 600px;
  height: auto;
  min-height: 100%;
  margin: 0;
  max-width: 100%;
  right: 0;
  transition: transform 0.3s ease-out;
}
.custom-order-rightSide-modal .modal-dialog {
  width: 75%;
}
.gamplan-custom-ngSelect
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  padding: 2px 8px !important;
  white-space: normal !important;
}
ng-select.gamplan-custom-ngSelect
  .ng-select-container.ng-has-value
  .ng-value-container {
  overflow-x: auto !important;
}
.call-click {
  div.popover-body {
    background: #66cece;
    color: #1f4848;
    border-radius: 4px;
    border: 1px solid #47a0a0;
  }
  .arrow::after {
    border-left-color: #47a0a0 !important;
  }
}
.chat-click {
  div.popover-body {
    background: #ca9657b0;
    color: #714b1c;
    border-radius: 4px;
    border: 1px solid #ab7634;
  }
  .arrow::after {
    border-left-color: #ab7634 !important;
  }
}
.update-click {
  div.popover-body {
    background: #4aa35185;
    color: #255829;
    border-radius: 4px;
    border: 1px solid #4aab52;
  }
  .arrow::after {
    border-left-color: #4aab52 !important;
  }
}
.pending-click {
  div.popover-body {
    background: #ed38338c;
    color: #611816;
    border-radius: 4px;
    border: 1px solid #d24d49;
  }
  .arrow::after {
    border-left-color: #d24d49 !important;
  }
}
.gameplan_modal .modal-content {
  border: none !important;
}
.gameplan_modal {
  .modal-dialog {
    max-width: 800px;
  }
}
.alert-dismissible .close {
  display: none !important;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 11000;
}
.gameplan_cust_header {
  background: #1d2c51;
  padding-top: 10px;
  padding-bottom: 10px;
}
.gameplan_modal2 .modal-content {
  border: none !important;
}

.cust-chat-btn {
  font-size: 20px !important;
  padding: 12px !important;
  border-radius: 50% !important;
}
.chat-attachment-btn {
  background: #1d2c51 !important;
}
.chat-send-btn {
  background: #4184f2 !important;
}

.profile_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e8e7f3;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #e8e7f3;
  cursor: pointer;
}
.profile_section {
  background: #fcfcfc;
  border: 1px solid #e8e7f3 !important;
  border-radius: 0px 0px 10px 10px !important;
}
.padding {
  padding: 15px;
}
.seperator {
  background: #e7e6eb;
  height: 1px;
  border: none;
  margin-top: 0px;
}
.degree-label {
  font-size: 0.9rem;
  font-weight: 600;
  flex: none;
}
// .badge-date{ background-color: #e2f3f3 !important; padding: 3px; font-weight: 600;}
.badge-date {
  background-color: #3fb5b07a !important;
  color: #0e1e77 !important;
  width: 110px;
  min-width: auto;
  padding: 3px;
  font-weight: 600;
}
.cust-profile-dropdown-menu::before {
  background: #dedede !important;
  border-top-color: #c7c3c3 !important;
  border-left-color: #c7c3c3 !important;
}
@media (min-width: 320px) and (max-width: 575px) {
  .profile_section {
    flex-wrap: wrap;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .profile_section {
    flex-wrap: wrap;
  }
}

.modal-content {
  border: 0px solid transparent !important;
}

.preview-container {
  input[type="checkbox"] {
    display: none;
  }

  img {
    transition: transform 0.25s ease;
    cursor: zoom-in;
  }

  input[type="checkbox"]:checked ~ label {
    img {
      margin: 200px;
      transform: scale(1.5);
      cursor: zoom-out;
    }
  }
}
ng-select.eligibility-ngSelect.ng-select {
  border-radius: 5px !important;
  div.ng-select-container {
    height: 35px !important;
    min-height: 10px;
    border-radius: 5px !important;
  }
}

.userDataReport {
  .bg-info {
    background-color: #3ca8e0 !important;
  }
}
.swal2-content {
  width: 100%;
}
.trackerTableData {
  .ngx-datatable.fixed-header
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell,
  datatable-header {
    background-color: #caa2a2;
  }
  .datatable-body-row.datatable-row-even
    .datatable-row-group.datatable-row-left {
    background-color: #eee;
  }
  .datatable-body-row.datatable-row-odd
    .datatable-row-group.datatable-row-left {
    background-color: #fff;
  }
}

.trackerTableData-iimi-report {
  .ngx-datatable.fixed-header
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell,
  datatable-header {
    background-color: #fff !important;
  }
}

.tracker-custom-ngSelect {
  ng-select ng-dropdown-panel.ng-dropdown-panel-items {
    max-height: 160px;
  }
}
ng-select.ng-select.tracker-custom-ngSelect
  ng-dropdown-panel.ng-dropdown-panel
  div.ng-dropdown-panel-items.scroll-host {
  max-height: 130px !important;
}
.iimi-filter .accordion > .card .card-header {
  padding: 7px 13px;
}

.iimi-filter .accordion > .card .card-body {
  font-size: 0.875rem;
  padding: 5px;
  font-weight: 400;
  background: #f3edff;
}

.iimi-filter .accordion > .card {
  margin: 0;
}

.accordion::-webkit-scrollbar {
  width: 3px;
}

.iimi-report .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 5px;
  font-size: 12px;
}
.globalExpertTable {
  .ngx-datatable.scroll-horz .datatable-body {
    overflow: scroll;
  }
  .datatable-body-row.datatable-row-even
    .datatable-row-group.datatable-row-right {
    background-color: #eee;
  }
  .datatable-body-row.datatable-row-odd
    .datatable-row-group.datatable-row-right {
    background-color: #fff;
  }
}
.tracker-custom-ngSelect {
  ng-select ng-dropdown-panel.ng-dropdown-panel-items {
    max-height: 160px;
  }
}
ng-select.ng-select.tracker-custom-ngSelect
  ng-dropdown-panel.ng-dropdown-panel
  div.ng-dropdown-panel-items.scroll-host {
  max-height: 130px !important;
}
.tracker-custom-ngSelect {
  ng-select ng-dropdown-panel.ng-dropdown-panel-items {
    max-height: 160px;
  }
}
ng-select.ng-select.tracker-custom-ngSelect
  ng-dropdown-panel.ng-dropdown-panel
  div.ng-dropdown-panel-items.scroll-host {
  max-height: 130px !important;
}

.modal {
  z-index: 5000 !important;
}

.ng-select div,
.ng-select input,
.ng-select span {
  margin-left: 0rem;
  .ng-option .row {
    gap: 1rem;
  }
}

span.datatable-header-cell-label.draggable{
  white-space: pre-wrap;
    word-break: break-word
}
